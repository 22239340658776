html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

html,
body,
* {
  box-sizing: border-box;
}

.button-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 100px;
}

.button-nav-to-appstore {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-weight: 500;
  box-shadow:
    0 0 0 3px rgba(0, 0, 0, 0.02),
    0 5px 6px rgba(0, 0, 0, 0.2);
  border-radius: 36px;
  padding: 15px 30px;
  transition: background-color 0.15s linear;
  background: #3c68dc;
  border: none;
  cursor: pointer;
  letter-spacing: 0.3px;
  font-size: 16px;
  margin: 0 20px;
  line-height: 20px;
}

.button-nav-to-appstore:hover {
  background: #4f74d2;
}
